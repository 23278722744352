<template>
  <div>
    <div
      class="bluebgdash px-4 radius-10-tl radius-10-tr py-4 "
      :class="isMd ? 'text-center' : 'd-flex align-center justify-space-between'"
    >
      <span class="register-primary--text font-16 font-700"
        ><span class="greytext--text">{{$t(leftTitle)}} > </span> {{ title }}</span
      >
      <div
        class="lightregister-primary px-6 py-2 d-flex align-center justify-center radius-5 cursor-pointer "
        :class="isMd ? 'w-100': ''"
        @click="$emit('click')"
        v-if="btnTitle"
      >
        <img class="mx-2" :src="icon" alt="" />
        <span class="font-16 font-600 darkprimary--text">{{ btnTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    btnTitle: {
      type: String,
      default: "",
    },
    leftTitle: {
      type: String,
      default: "dashboard.profile",
    },
  },
};
</script>

<style></style>
