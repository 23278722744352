<template>
  <div class="chat">
    <div class="register-primary px-2 py-2 d-flex align-center radius-10-trl">
      <div
        class="chat-user-cover white radius-15 mx-4 d-flex align-center justify-center"
      >
        <img
          class="object-cover radius-15 mx-auto"
          width="50px"
          height="50px"
          :src="require('@/assets/images/blog-img.png')"
          alt=""
        />
      </div>
      <span class="font-15 white--text font-600">{{
        doctorInfo.first_name + " " + doctorInfo.last_name
      }}</span>
    </div>
    <div class="chat-content px-2 py-2 bluebgdash py-4" id="messages">
      <div>
        <div
          class="chat-content__message py-2 px-4 radius-5 mb-4 d-flex align-center justify-space-between"
          v-for="(message, i) in messages"
          :key="i"
          :class="message.type == 1 ? 'white' : 'register-primary ml-auto'"
        >
          <span :class="message.type == 1 ? 'black--text' : 'white--text'">{{
            message.message
          }}</span>
          <span :class="message.type == 1 ? 'black--text' : 'white--text'">{{
            message.time
          }}</span>
        </div>
      </div>
    </div>

    <div class="white px-2 py-2 mt-4">
      <v-text-field
        v-model="msg"
        append-icon="mdi-send"
        outlined
        placeholder="Message"
        type="text"
        height="50px"
        :hide-details="true"
        dense
        color="white"
        @click:append="sendMessage"
        @keypress.enter="sendMessage"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    doctorInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    messages: [],
    msg: "",
  }),
  computed: {
    ...mapGetters(["profile"]),
    role() {
      return localStorage.getItem("role");
    },
  },
  methods: {
    async getMessages() {
      let { data } = await this.$axios.post("get_messages", {
        hospital_id: this.profile.id,
        doctor_id: +this.$route.params.id,
      });
      if (data) {
        this.messages = data.data.reverse();
        setTimeout(() => {
          var objDiv = document.getElementById("messages");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1);
      }
    },
    async sendMessage() {
      let obj = {
        type: localStorage.getItem("role") == "doctor" ? "doctor" : "hospital",
        doctor_id: this.$route.params.id,
        hospital_id: this.profile.id,
        message: this.msg,
      };

      let { data } = await this.$axios.post("send_doctor_message", obj, {
        headers: {
          Accept: "application/json",
        },
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Sent Successfully",
          color: "success",
        });
        this.messages.push({
          message: this.msg,
          type: localStorage.getItem("role") == "doctor" ? 0 : 1,
          time: this.$moment().format("LT"),
        });
        setTimeout(() => {
          var objDiv = document.getElementById("messages");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 1);
        this.msg = "";
      } else {
        this.$store.dispatch("showSnack", {
          text: "Wrong",
          color: "error",
        });
      }
    },
  },
  async mounted() {
    await this.getMessages();
  },
};
</script>

<style lang="scss">
.chat {
  .chat-user-cover {
    padding: 3px 3px 3px 4px !important;
  }
  .chat-content {
    height: calc(100vh - 35vh);
    overflow-y: scroll;
    &__message {
      max-width: 70%;
    }
  }
  .v-input__append-inner {
    width: 50px !important;
    height: 100%;
    background: $register-primary;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    i {
      color: white;
    }
  }
  .v-input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
  }
  .v-input__slot {
    padding: auto 0px !important;
    fieldset {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
