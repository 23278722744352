<template>
  <div class="dashboard-documnets white radius-15 pa-1 pb-4">
    <profile-head
      :leftTitle="$t('dashboard.allDoctors')"
      :title="doctorInfo.first_name + ' ' + doctorInfo.last_name"
    ></profile-head>

    <div class="white pa-1 radius-5 mt-1">
      <v-row>
        <v-col cols="5" class="px-2">
          <div class="bluebgdash pa-2 radius-5">
            <!-- profile details -->
            <mini-profile :moreBtn="true"></mini-profile>
          </div>

          <!-- education  -->
          <div
            class="bluebgdash mt-3 radius-5 py-6"
            v-for="(time, i) in times"
            :key="i"
          >
            <span class="font-20 font-600 d-block px-8 mb-3">{{
              time.title
            }}</span>
            <v-timeline dense clipped align-top>
              <v-timeline-item v-for="(data, j) in time.data" :key="j" large>
                <span class="font-16 d-block" v-if="time.type == 'Education'">{{
                  data.name
                }}</span>
                <span
                  class="font-15 lightgreytext--text d-block"
                  v-if="time.type == 'Education' && data.created_at"
                  >{{ data.created_at.slice(0, 10) }}</span
                >

                <span class="font-16 d-block" v-if="time.type != 'Education'">{{
                  data.company_name
                }}</span>
                <span
                  class="font-15 lightgreytext--text d-block"
                  v-if="
                    time.type != 'Education' && data.from_date && data.to_date
                  "
                  >{{ data.from_date.slice(0, 10) }} -
                  {{ data.to_date.slice(0, 10) }}</span
                >
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-col>
        <v-col cols="7" class="px-2">
          <chat :doctorInfo="doctorInfo"></chat>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MiniProfile from "../../../components/dialogs/MiniProfile.vue";
import ProfileHead from "../../../components/ProfileHead.vue";
import Chat from "./components/Chat.vue";
export default {
  components: { ProfileHead, MiniProfile, Chat },
  data: (vm) => ({
    times: [
      { title: vm.$t("dashboard.education"), type: "Education", data: [] },
      {
        title: vm.$t("dashboard.workExperience"),
        type: "Work & Experience",
        data: [],
      },
    ],
    doctorInfo: {},
  }),
  methods: {
    async getDoctorInfo() {
      let { data } = await this.$axios.post("doctor/information", {
        doctor_id: this.$route.params.id,
      });
      if (data.success) {
        this.doctorInfo = data.data[0];
        this.times[0].data = this.doctorInfo.degrees;
        this.times[1].data = this.doctorInfo.job_experience;
      }
    },
  },
  async created() {
    await this.getDoctorInfo();
  },
};
</script>

<style lang="scss">
.v-timeline {
  padding-top: 0px;
}
.v-timeline-item__dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #29aa94 !important;
  position: absolute;
  left: calc(50% - 10px) !important;

  .v-timeline-item__inner-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
    background: #d2f2fd !important;
    margin: 0px !important;
  }
}
.theme--light.v-timeline:before {
  width: 3px;
  transform: translateX(-1px);
  background: #29aa94;
}
</style>
