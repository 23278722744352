<template>
  <div>
    <v-row>
      <!-- profile image -->
      <v-col cols="5">
        <img
          width="100%"
          class="radius-15 object-cover"
          :height="moreBtn ? '220px' : '150px'"
          :src="require('@/assets/images/blog-img.png')"
        />
      </v-col>
      <v-col cols="7">
        <span
          class="d-block font-18 font-600 darkbord--text mt-1 text-capitalize"
          >{{ data.first_name }} - {{ data.last_name }}
        </span>
        <span class="d-block font-18 bluetextdash--text"
          >{{ data.specializations[0].name }}
        </span>

        <v-row class="mt-2">
          <v-col class="py-2" cols="6" v-for="(detail, i) in details" :key="i">
            <div class="d-flex align-center">
              <img :class="$vuetify.rtl ? 'ml-3': 'mr-3'" :src="detail.icon" alt="" />
              <span class="font-16 darkbord--text text-capitalize">{{
                detail.title
              }}</span>
            </div>
          </v-col>
        </v-row>

        <!-- more btn -->
        <v-col class="px-0 mt-4" cols="6" v-if="moreBtn">
          <v-btn
            color="#767676"
            dark
            outlined
            elevation="0"
            class="radius-5"
            height="48px"
            width="140px"
            @click="$emit('moreClicked')"
          >
            <span class="font-700 font-16">More</span>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xl="6" class="py-1" v-if="data.degrees.length > 0">
        <span
          class="font-16 font-600 d-block darkbord--text text-capitalize mb-2"
          >{{ $t("dashboard.degrees") }}</span
        >
        <div
          class="bordercolor px-3 py-2 radius-5 mb-3"
          v-for="(item, i) in data.degrees"
          :key="i"
        >
          <span class="darkbord--text font-14">{{ item.name }}</span>
        </div>
      </v-col>
      <v-col
        cols="12"
        xl="6"
        class="py-0"
        v-if="data.specializations.length > 0"
      >
        <span
          class="font-16 font-600 d-block darkbord--text text-capitalize mb-2"
          >{{ $t("dashboard.specializations") }}</span
        >
        <div
          class="bordercolor px-3 py-2 radius-5 mb-3"
          v-for="(item, i) in data.specializations"
          :key="i"
        >
          <span class="darkbord--text font-14">{{ item.name }}</span>
        </div>
      </v-col>

      <v-col cols="6" v-if="!moreBtn">
        <v-btn
          color="register-primary"
          dark
          elevation="0"
          class="radius-5"
          height="44px"
          @click="$router.push(`/dashboard/doctors/${idKey}`)"
          block
        >
          <div class="d-flex align-center">
            <img
              class="mr-2"
              :src="
                require('@/assets/images/dashboard/icons/message-white.svg')
              "
            />
            <span class="font-700 font-16">{{$t('forms.sendMessage')}}</span>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="6" v-if="!moreBtn">
        <v-btn
          color="register-primary"
          dark
          outlined
          elevation="0"
          class="radius-5"
          height="44px"
          @click="$router.push(`/dashboard/doctors/${idKey}`)"
          block
        >
          <div class="d-flex align-center">
            <img
              class="mx-2"
              :src="require('@/assets/images/dashboard/icons/view-green.svg')"
            />
            <span class="font-700 font-16">{{
              $t("dashboard.viewProfile")
            }}</span>
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    moreBtn: {
      type: Boolean,
      default: false,
    },
    idKey: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    details: [
      {
        title: "Permanent",
        icon: require("@/assets/images/dashboard/icons/bag.svg"),
      },
      {
        title: "1000",
        icon: require("@/assets/images/dashboard/icons/dollar.svg"),
      },
      {
        title: "Male /57 years",
        icon: require("@/assets/images/dashboard/icons/male.svg"),
      },
      {
        title: "Lebanon",
        icon: require("@/assets/images/dashboard/icons/location.svg"),
      },
    ],
    data: {},
  }),
  methods: {
    async getData() {
      let { data } = await this.$axios.post("doctor/information", {
        doctor_id: this.idKey,
      });
      if (data) {
        this.data = data.data[0];
        this.details[0].title = this.data.job_type;
        this.details[1].title = this.data.expected_salary_usd;
        this.details[2].title = this.data.gender;
        this.details[3].title = this.data.nationality;
      }
    },
  },
  async created() {
    if (this.idKey) {
      await this.getData();
    }
  },
};
</script>

<style></style>
